<template>
  <div class="contain">
    <v-progress-linear
      fixed
      :active="loading"
      :indeterminate="true"
    ></v-progress-linear>
    <v-container>
      <v-row class="mb-6" justify="center">
        <v-col cols="12" lg="11" xl="9">
          <v-row>
            <v-col cols="12">
              <v-fade-transition>
                <div v-show="compiler">
                  <div class="text-h6">Operativo</div>
                  <v-card style="border-radius: 10px" class="mt-4 mb-10">
                    <v-img
                      lazy-src="../../assets/images/default.png"
                      :src="background_image"
                      gradient="to left, rgba(0,0,0,.2), rgba(0,0,0,.6)"
                      class="white--text align-end"
                      height="200"
                      style="border-radius: 10px"
                    >
                      <v-container>
                        <v-card-text>
                          <div class="text-h6">Docente</div>
                          <div class="text-body-2">
                            {{ teacher.name }}
                          </div>
                          <v-btn @click="getData()" class="mt-4" outlined dark>
                            <v-avatar size="30">
                              <v-img :src="profile_url"></v-img>
                            </v-avatar>
                            <div
                              class="text-subtitle-2 ml-4"
                              style="text-transform: none"
                            >
                              Datos personales
                            </div>
                          </v-btn>
                        </v-card-text>
                      </v-container>
                    </v-img>
                  </v-card>
                </div>
              </v-fade-transition>
              <v-slide-y-reverse-transition>
                <div v-show="compiler">
                  <div class="text-h6">Asignaturas ({{ lessons.length }})</div>
                  <v-row class="my-1">
                    <v-col
                      v-for="lesson in lessons"
                      :key="lesson.id"
                      cols="12"
                      sm="6"
                      md="3"
                      xl="2"
                    >
                      <v-hover v-slot="{ hover }">
                        <v-card
                          @click="openLesson(lesson.id)"
                          link
                          :elevation="hover ? 4 : 2"
                          style="border-radius: 10px"
                          :style="{
                            background: $vuetify.theme.themes[theme].images,
                          }"
                        >
                          <v-img
                            lazy-src="../../assets/images/default.png"
                            :src="
                              require('../../assets/subjects/' +
                                lesson.icon +
                                '.png')
                            "
                            contain
                          ></v-img>
                          <v-card-text>
                            <div
                              class="
                                text-subtitle-2 text--primary text-truncate
                                mt-5
                              "
                            >
                              {{ lesson.name }}
                            </div>
                            <div class="text-caption">
                              {{
                                lesson.complementary
                                  ? "Complementaria"
                                  : "Regular"
                              }}
                            </div>
                          </v-card-text>
                          <v-divider></v-divider>
                          <v-card-text class="text-right text-h6">
                            {{ lesson.semester }}° {{ lesson.groups }}
                          </v-card-text>
                        </v-card>
                      </v-hover>
                    </v-col>
                  </v-row>
                </div>
              </v-slide-y-reverse-transition>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-dialog v-model="action" fullscreen hide-overlay scrollable>
        <v-card
          :style="{
            background: $vuetify.theme.themes[theme].images,
          }"
        >
          <v-toolbar
            :style="{
              background: $vuetify.theme.themes[theme].images,
            }"
          >
            <v-app-bar-nav-icon class="ml-1">
              <v-btn @click="action = false" icon>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-app-bar-nav-icon>
            <v-toolbar-title class="text-subtitle-1 font-weight-medium">
              Perfil
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              @click="save()"
              color="primary"
              dark
              elevation="0"
              class="mr-2"
            >
              <div class="text-subtitle-2 mx-2" style="text-transform: none">
                Guardar
              </div>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-row justify="center" class="my-8">
              <v-col cols="12" md="9" lg="8" xl="7">
                <v-row>
                  <v-col cols="12" md="4" lg="3">
                    <div class="text-center">
                      <v-avatar size="150">
                        <v-img
                          lazy-src="../../assets/images/default.png"
                          :src="profile_url"
                        ></v-img>
                      </v-avatar>
                    </div>
                    <div class="text-center">
                      <v-btn
                        @click="custom = !custom"
                        color="primary"
                        outlined
                        elevation="0"
                        class="mt-8 mb-4"
                      >
                        <div
                          class="text-subtitle-2"
                          style="text-transform: none"
                        >
                          Cambiar imagen
                        </div>
                        <v-icon class="ml-4">
                          {{ custom ? "mdi-chevron-up" : "mdi-chevron-down" }}
                        </v-icon>
                      </v-btn>
                    </div>
                    <v-expand-transition>
                      <div v-show="custom">
                        <v-file-input
                          v-model="image"
                          label="Seleccionar nueva"
                          accept="image/png, image/jpeg"
                          hide-details
                        ></v-file-input>
                        <v-btn
                          @click="uploadImage()"
                          color="primary"
                          dark
                          class="my-4"
                        >
                          <div
                            class="text-subtitle-2"
                            style="text-transform: none"
                          >
                            Guardar nueva
                          </div>
                        </v-btn>
                      </div>
                    </v-expand-transition>
                  </v-col>
                  <v-col cols="12" md="8" lg="9">
                    <div class="text-h5 font-weight-bold text--primary">
                      Cuenta en CEi
                    </div>
                    <div class="text-subtitle-2 mt-2">De tipo docente</div>
                    <v-text-field
                      v-model="teacher.name"
                      hide-details
                      filled
                      dense
                      label="Nombre completo"
                      class="mt-4"
                    ></v-text-field>
                    <v-text-field
                      v-model="teacher.password"
                      type="password"
                      hide-details
                      filled
                      dense
                      label="Contraseña"
                      disabled
                      class="my-4"
                    ></v-text-field>
                    <v-checkbox
                      v-model="teacher_data.academy"
                      label="Presidente(a) de academia"
                      hide-details
                      disabled
                    ></v-checkbox>
                    <div class="text-subtitle-2 mt-8 mb-4">
                      Información personal
                    </div>
                    <v-text-field
                      v-model="teacher_data.birth_date"
                      hide-details
                      filled
                      dense
                      label="Fecha de nacimiento"
                    ></v-text-field>
                    <v-text-field
                      v-model="teacher_data.birth_place"
                      hide-details
                      filled
                      dense
                      label="Lugar de nacimiento"
                      class="my-4"
                    ></v-text-field>
                    <v-text-field
                      v-model="teacher_data.social_security"
                      hide-details
                      filled
                      dense
                      label="Número de seguridad social"
                    ></v-text-field>
                    <v-text-field
                      v-model="teacher_data.personal_number"
                      hide-details
                      filled
                      dense
                      label="Número de personal"
                      class="my-4"
                    ></v-text-field>
                    <v-text-field
                      v-model="teacher_data.designation"
                      hide-details
                      filled
                      dense
                      label="Nombramiento"
                    ></v-text-field>
                    <v-text-field
                      v-model="teacher_data.squares_key"
                      hide-details
                      filled
                      dense
                      label="Clave de plazas"
                      class="my-4"
                    ></v-text-field>
                    <v-text-field
                      v-model="teacher_data.member_since"
                      hide-details
                      filled
                      dense
                      label="Miembro desde"
                    ></v-text-field>
                    <div class="text-subtitle-2 mt-8 mb-4">Escolaridad</div>
                    <v-text-field
                      v-model="teacher_data.degree"
                      hide-details
                      filled
                      dense
                      label="Licenciatura"
                    ></v-text-field>
                    <v-text-field
                      v-model="teacher_data.degree_number"
                      hide-details
                      filled
                      dense
                      label="Cédula de licenciatura"
                      class="my-4"
                    ></v-text-field>
                    <v-text-field
                      v-model="teacher_data.master"
                      hide-details
                      filled
                      dense
                      label="Maestría"
                    ></v-text-field>
                    <v-text-field
                      v-model="teacher_data.master_number"
                      hide-details
                      filled
                      dense
                      label="Cédula de la maestría"
                      class="my-4"
                    ></v-text-field>
                    <v-text-field
                      v-model="teacher_data.doctorate"
                      hide-details
                      filled
                      dense
                      label="Doctorado"
                    ></v-text-field>
                    <v-text-field
                      v-model="teacher_data.doctorate_number"
                      hide-details
                      filled
                      dense
                      label="Cédula del doctorado"
                      class="mt-4"
                    ></v-text-field>
                    <div class="text-subtitle-2 mt-8 mb-4">
                      Domicilio del docente
                    </div>
                    <v-text-field
                      v-model="teacher_data.street"
                      hide-details
                      filled
                      dense
                      label="Calle"
                    ></v-text-field>
                    <v-text-field
                      v-model="teacher_data.number"
                      hide-details
                      filled
                      dense
                      label="Número"
                      class="my-4"
                    ></v-text-field>
                    <v-text-field
                      v-model="teacher_data.interior"
                      hide-details
                      filled
                      dense
                      label="Interior"
                    ></v-text-field>
                    <v-text-field
                      v-model="teacher_data.postal_code"
                      hide-details
                      filled
                      dense
                      label="Código postal"
                      class="my-4"
                    ></v-text-field>
                    <v-text-field
                      v-model="teacher_data.colony"
                      hide-details
                      filled
                      dense
                      label="Colonia"
                    ></v-text-field>
                    <div class="text-subtitle-2 mt-8 mb-4">
                      Medios de contacto
                    </div>
                    <v-text-field
                      v-model="teacher_data.phone"
                      hide-details
                      filled
                      dense
                      label="Teléfono"
                    ></v-text-field>
                    <v-row class="mt-4">
                      <v-col cols="6">
                        <v-text-field
                          v-model="is_mail_name"
                          hide-details
                          filled
                          label="Correo institucional"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-select
                          v-model="is_mail_domain"
                          :items="mails"
                          hide-details
                          outlined
                          single-line
                          prefix="@"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <v-text-field
                          v-model="ps_mail_name"
                          hide-details
                          filled
                          label="Correo personal"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-select
                          v-model="ps_mail_domain"
                          :items="mails"
                          hide-details
                          outlined
                          single-line
                          prefix="@"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-snackbar v-model="responseView" timeout="2000" color="info" top right>
        <div class="text-center text-subtitle-2">
          {{ responseMessage }}
        </div>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
// plugins
import auth from "../../plugins/auth";
import settings from "../../plugins/settings";
import periods from "../../plugins/periods";
import lessons from "../../plugins/lessons";
import teachers from "../../plugins/teachers";
import teachers_data from "../../plugins/teachers_data";
import files from "../../plugins/files";

// resources
import endpoint from "../../plugins/endpoint";
import profile_img from "../../assets/images/profile.png";

export default {
  name: "Operative",

  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },

  created() {
    this.main();
  },

  data: () => ({
    period: "",
    period_model: "",
    background_image: "",

    teacher: {},
    is_mail_name: "",
    is_mail_domain: "msev.gob.mx",
    ps_mail_name: "",
    ps_mail_domain: "bac.edu.mx",
    teacher_data: {},

    // Correos
    mails: [
      "bac.edu.mx",
      "msev.gob.mx",
      "hotmail.com",
      "hotmail.es",
      "hotmail.com.es",
      "outlook.com",
      "outlook.es",
      "outlook.com.es",
      "gmail.com",
      "yahoo.com",
      "yahoo.es",
      "yahoo.com.es",
    ],

    action: false,

    back_url: "",
    profile_url: profile_img,
    image: [],

    lessons: [],
    header: "",

    loading: false,
    compiler: false,
    locked: true,
    custom: false,
    responseView: false,
    responseMessage: "",
  }),

  methods: {
    // start chain
    async main() {
      if (!auth.getSession()) this.$router.push("/");
      else {
        this.loading = true;

        await auth.permission().then((response) => {
          if (response.data.directive == "teacher") this.getProfile();
          else this.$router.push("/");
        });
      }
    },

    async getProfile() {
      await auth.profile().then((response) => {
        this.teacher = response.data;
        this.profile_url = endpoint.BASE_PATH + this.teacher.profile_image;

        if (this.teacher.mail != "" && this.teacher.mail != null) {
          var is_mail = this.teacher.mail.toLowerCase().split("@");
          this.is_mail_name = is_mail[0];
          this.is_mail_domain = is_mail[1];
        }

        this.getSettings();
      });
    },

    async getSettings() {
      await settings.getOne(1).then((response) => {
        this.period = response.data.period;
        this.background_image =
          endpoint.BASE_PATH + response.data.background_image;

        this.getPeriod();
      });
    },

    async getPeriod() {
      await periods.getOne(this.period).then((response) => {
        this.period_model = response.data;

        this.getLessons();
      });
    },

    async getLessons() {
      await lessons
        .listByTeacher(this.period, this.teacher.id)
        .then((response) => {
          this.lessons = response.data;

          this.loading = false;
          this.compiler = true;
        });
    },

    // invoked
    openLesson(lesson) {
      this.$router.push("/op/ls/" + lesson);
    },

    async getData() {
      this.loading = true;
      await teachers_data.getOne(this.teacher.id).then((response) => {
        this.teacher_data = response.data;

        if (this.teacher_data.mail != "" && this.teacher_data.mail != null) {
          var ps_mail = this.teacher_data.mail.toLowerCase().split("@");
          this.ps_mail_name = ps_mail[0];
          this.ps_mail_domain = ps_mail[1];
        }

        this.action = true;
        this.loading = false;
      });
    },

    async save() {
      if (
        this.teacher.name != null &&
        this.teacher.username != null &&
        this.teacher.password != null
      ) {
        this.loading = true;

        this.saveTeacher();
      }
    },

    async saveTeacher() {
      await teachers.update(this.teacher.id, this.teacher).then((response) => {
        this.saveData();
        this.responseMessage = response.data.message;
      });
    },

    async saveData() {
      await teachers_data
        .update(this.teacher_data.id, this.teacher_data)
        .then((response) => {
          this.responseMessage = response.data.message;

          this.action = false;
          this.responseView = true;
          this.loading = false;
        });
    },

    async uploadImage() {
      this.loading = true;

      const formData = new FormData();
      formData.append("file", this.image);

      await files
        .teachersProfile(formData, this.teacher.id)
        .then((response) => {
          this.teacher.profile_image = response.data.path;
          this.save();
          this.loading = false;

          location.reload();
        });
    },
  },
};
</script>