import axios from "axios";
import end from './endpoint';
const ENDPOINT = end.PATH + "reports/";

export default {
    // --- REINCO ---
    getReinco(type, subject, lesson, semester, group, period, partial) {
        const path = ENDPOINT + subject + "/" + lesson + "/" + semester + "/" + group + "/" + period + "/" + partial + "/" + type;
        return axios({ url: path, method: 'GET', responseType: 'blob' });
    },

    getExamples(report) {
        const path = ENDPOINT + report;
        return axios({ url: path, method: 'GET', responseType: 'blob' });
    },

    // --- ratings ---
    getRatings(inscription, partial) {
        const path = ENDPOINT + inscription + "/" + partial;
        return axios({ url: path, method: 'GET', responseType: 'blob' });
    },

    // --- inscriptions ---
    receipt(inscription, date) {
        return axios({ url: `${ENDPOINT}ins/receipt/${inscription}/${date}`, method: 'GET', responseType: 'blob' });
    },

    pdfActiveStudents(semester, groups, period) {
        const path = ENDPOINT + semester + "/" + groups + "/" + period;
        return axios({ url: path, method: 'GET', responseType: 'blob' });
    },

    excelActiveStudents(semester, groups, period) {
        const path = ENDPOINT + "excel/" + semester + "/" + groups + "/" + period;
        return axios({ url: path, method: 'GET', responseType: 'blob' });
    },
};