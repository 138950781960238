<template>
  <div class="contain">
    <v-progress-linear
      fixed
      :active="loading"
      :indeterminate="true"
    ></v-progress-linear>
    <v-container>
      <v-row>
        <v-col cols="12">
          <a @click="goBack()">
            <div class="text-subtitle-2">
              <v-icon class="mr-2" color="primary">mdi-chevron-left</v-icon>
              Clases impartidas
            </div>
          </a>
        </v-col>
        <v-fade-transition>
          <v-col cols="12" lg="4" xl="3" v-show="compiler">
            <v-card
              style="border-radius: 10px"
              :style="{
                background: $vuetify.theme.themes[theme].images,
              }"
              class="mb-6"
            >
              <v-card-text>
                <v-row align="center">
                  <v-col cols="12" md="3" lg="12">
                    <div class="text-center">
                      <div class="mt-5 d-md-none d-lg-flex"></div>
                      <v-avatar size="120">
                        <v-img
                          lazy-src="../../../assets/images/default.png"
                          :src="
                            require('../../../assets/subjects/' +
                              subject_icon +
                              '.png')
                          "
                        ></v-img>
                      </v-avatar>
                    </div>
                  </v-col>
                  <v-col cols="12" md="6" lg="12">
                    <div class="text-center">
                      <div class="text-h3 text--primary mb-2">
                        {{ lesson.semester }}° {{ lesson.groups }}
                      </div>
                      <div class="text-subtitle-2 text-truncate">
                        {{ subject.name }}, {{ students.length }} inscritos
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" md="3" lg="12">
                    <div class="text-center">
                      <v-btn
                        @click="custom = !custom"
                        color="primary"
                        outlined
                        elevation="0"
                      >
                        <div
                          class="text-subtitle-2"
                          style="text-transform: none"
                        >
                          Lista de clase
                        </div>
                        <v-icon class="ml-2">
                          {{ custom ? "mdi-chevron-up" : "mdi-chevron-down" }}
                        </v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-expand-transition>
                <div v-show="custom">
                  <v-divider></v-divider>
                  <v-card-text>
                    <div class="text-subtitle-2">
                      Alumnos inscritos ({{ students.length }})
                    </div>
                  </v-card-text>
                  <v-list-item
                    link
                    v-for="student in students"
                    :key="student.id"
                  >
                    <v-list-item-avatar size="30">
                      <v-img
                        lazy-src="../../../assets/images/default.png"
                        :src="back_url + student.photo"
                      ></v-img>
                    </v-list-item-avatar>
                    <v-list-item-title class="text-subtitle-2">
                      <strong class="primary--text">
                        {{ student.number }}.
                      </strong>
                      {{ student.paternal_surname }}
                      {{ student.maternal_surname }} {{ student.names }}
                    </v-list-item-title>
                  </v-list-item>
                </div>
              </v-expand-transition>
              <v-divider></v-divider>
              <v-card-text>
                <v-select
                  v-model="period_model.partial"
                  v-on:input="getPartial()"
                  :items="partials"
                  item-value="id"
                  item-text="name"
                  menu-props="auto"
                  outlined
                  hide-details
                  label="Parcial (NO APLICA prueba escrita)"
                  class="mt-2"
                ></v-select>
              </v-card-text>
              <v-row no-gutters>
                <v-col
                  v-for="report in reports"
                  :key="report.id"
                  cols="6"
                  sm="4"
                  md="3"
                  lg="6"
                >
                  <v-card
                    @click="reincoDownload(report.id, report.name)"
                    :style="{
                      background: $vuetify.theme.themes[theme].images,
                    }"
                    link
                    outlined
                    style="border-radius: 10px"
                  >
                    <v-card-text>
                      <div class="text-center">
                        <v-icon class="my-4" color="primary">
                          {{ report.icon }}
                        </v-icon>
                        <div
                          class="text-subtitle-2 text-truncate text--primary"
                        >
                          {{ report.name }}
                        </div>
                        <div class="text-caption">(Reinco)</div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-fade-transition>
        <v-slide-y-reverse-transition>
          <v-col cols="12" lg="8" xl="9" v-show="compiler">
            <v-card
              v-show="period_model.evaluation"
              style="border-radius: 10px"
              :style="{
                background: $vuetify.theme.themes[theme].images,
              }"
              class="mb-4"
            >
              <v-list-item
                @click="goRatings()"
                style="border-radius: 10px"
                link
              >
                <v-list-item-avatar>
                  <v-icon color="primary">mdi-file-star-outline</v-icon>
                </v-list-item-avatar>
                <v-list-item-title class="text-subtitle-2">
                  Calificaciones de prueba escrita
                </v-list-item-title>
                <v-list-item-action>
                  <v-icon>mdi-chevron-right</v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-card>
            <v-card
              style="border-radius: 10px"
              :style="{
                background: $vuetify.theme.themes[theme].images,
              }"
            >
              <v-list-item>
                <v-list-item-title class="text-subtitle-2">
                  Asistencias
                </v-list-item-title>
              </v-list-item>
              <v-divider></v-divider>
              <v-card-text>
                <div class="text-body-2 text--primary mb-4">
                  <strong class="primary--text">
                    {{ assists.length }} pases de lista
                  </strong>
                  registrados en {{ period_model.partial }}° parcial
                </div>
                <v-btn @click="openAssist()" color="primary" dark elevation="0">
                  <div
                    class="text-subtitle-2 mx-2"
                    style="text-transform: none"
                  >
                    Nuevo registro
                  </div>
                </v-btn>
              </v-card-text>
              <v-divider></v-divider>
              <v-data-iterator
                :items="assists"
                item-key="id"
                :items-per-page.sync="itemsPerPageAs"
                :page.sync="pageAs"
                no-data-text=""
                no-results-text=""
                hide-default-footer
                class="my-6"
              >
                <template v-slot:header>
                  <v-row class="mx-1 mb-2" align="center">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          class="ml-2"
                          outlined
                          v-bind="attrs"
                          v-on="on"
                        >
                          <div
                            class="text-subtitle-2"
                            style="text-transform: none"
                          >
                            Últimos {{ itemsPerPageAs }}
                          </div>
                          <v-icon>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(number, index) in itemsPerPageArrayAs"
                          :key="index"
                          @click="updateItemsPerPageAs(number)"
                        >
                          <v-list-item-title>
                            {{ number }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-spacer></v-spacer>
                    <div class="text-body-2 text--secondary">
                      Lista {{ pageAs }} de {{ numberOfPagesAs }}
                    </div>
                    <v-btn icon class="mx-1 my-2" @click="formerPageAs">
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-btn icon class="ml-1 mr-2 my-2" @click="nextPageAs">
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-row>
                  <v-divider></v-divider>
                </template>
                <template v-slot:default="{ items }">
                  <template v-for="(item, i) in items">
                    <v-divider v-if="i !== 0" :key="`${i}-divider`"></v-divider>
                    <v-list-item
                      @click="goAssist(item.id)"
                      link
                      :key="`${i}-${item.id}`"
                    >
                      <v-list-item-avatar>
                        <v-img
                          lazy-src="../../../assets/images/default.png"
                          src="../../../assets/images/assists.png"
                        ></v-img>
                      </v-list-item-avatar>
                      <v-list-item-title class="text-subtitle-2">
                        Del {{ item.dated }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </template>
              </v-data-iterator>
            </v-card>
            <v-card
              style="border-radius: 10px"
              :style="{
                background: $vuetify.theme.themes[theme].images,
              }"
              class="my-6"
            >
              <v-list-item>
                <v-list-item-title class="text-subtitle-2">
                  Evidencias
                </v-list-item-title>
              </v-list-item>
              <v-divider></v-divider>
              <v-card-text>
                <div class="text-body-2 text--primary mb-4">
                  <strong class="primary--text">
                    {{ evidences.length }} evidencias
                  </strong>
                  registradas en {{ period_model.partial }}° parcial
                </div>
                <v-btn
                  @click="openEvidence()"
                  color="primary"
                  dark
                  elevation="0"
                >
                  <div
                    class="text-subtitle-2 mx-2"
                    style="text-transform: none"
                  >
                    Nueva evidencia
                  </div>
                </v-btn>
              </v-card-text>
              <v-divider></v-divider>
              <v-data-iterator
                :items="evidences"
                item-key="id"
                :items-per-page.sync="itemsPerPageEv"
                :page.sync="pageEv"
                no-data-text=""
                no-results-text=""
                hide-default-footer
                class="my-6"
              >
                <template v-slot:header>
                  <v-row class="mx-1 mb-2" align="center">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          class="ml-2"
                          outlined
                          v-bind="attrs"
                          v-on="on"
                        >
                          <div
                            class="text-subtitle-2"
                            style="text-transform: none"
                          >
                            Últimos {{ itemsPerPageEv }}
                          </div>
                          <v-icon>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(number, index) in itemsPerPageArrayEv"
                          :key="index"
                          @click="updateItemsPerPageEv(number)"
                        >
                          <v-list-item-title>
                            {{ number }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-spacer></v-spacer>
                    <div class="text-body-2 text--secondary">
                      Lista {{ pageEv }} de {{ numberOfPagesEv }}
                    </div>
                    <v-btn icon class="mx-1 my-2" @click="formerPageEv">
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-btn icon class="ml-1 mr-2 my-2" @click="nextPageEv">
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-row>
                  <v-divider></v-divider>
                </template>
                <template v-slot:default="{ items }">
                  <template v-for="(item, i) in items">
                    <v-divider v-if="i !== 0" :key="`${i}-divider`"></v-divider>
                    <v-list-item
                      @click="goEvidence(item.id)"
                      link
                      :key="`${i}-${item.id}`"
                    >
                      <v-list-item-avatar>
                        <v-img
                          lazy-src="../../../assets/images/default.png"
                          src="../../../assets/images/evidences.png"
                        ></v-img>
                      </v-list-item-avatar>
                      <v-list-item-title class="text-subtitle-2">
                        El {{ item.dated }} se calificó {{ item.type }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </template>
              </v-data-iterator>
              <v-divider></v-divider>
              <v-list-item @click="goRisk()" link>
                <v-list-item-avatar>
                  <v-icon color="error">mdi-alert-rhombus-outline</v-icon>
                </v-list-item-avatar>
                <v-list-item-title class="text-subtitle-2">
                  Ver alumnos en riesgo
                </v-list-item-title>
              </v-list-item>
            </v-card>
          </v-col>
        </v-slide-y-reverse-transition>
      </v-row>
      <v-dialog v-model="assist_action" width="400">
        <v-card
          style="border-radius: 10px"
          :style="{
            background: $vuetify.theme.themes[theme].images,
          }"
        >
          <v-card-title class="text-h5 font-weight-bold">
            Pase de lista
          </v-card-title>
          <v-card-text>
            <div class="text-subtitle-2 mt-2">
              Información del registro de asistencia
            </div>
            <v-select
              v-model="period_model.partial"
              v-on:input="getPartial()"
              :items="partials"
              item-value="id"
              item-text="name"
              menu-props="auto"
              outlined
              hide-details
              label="Correspondiente al"
              class="my-4"
            ></v-select>
            <v-text-field
              v-model="assist.date"
              type="date"
              filled
              dense
              hide-details
              label="Fecha del pase"
              class="mb-4"
            ></v-text-field>
          </v-card-text>
          <v-card-text
            :style="{
              background: $vuetify.theme.themes[theme].background,
            }"
          >
            <v-row align="center">
              <v-col cols="6">
                <v-btn
                  @click="saveAssist()"
                  color="primary"
                  dark
                  elevation="0"
                  block
                >
                  <div class="text-subtitle-2" style="text-transform: none">
                    Guardar
                  </div>
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn
                  @click="assist_action = false"
                  outlined
                  elevation="0"
                  block
                >
                  <div class="text-subtitle-2" style="text-transform: none">
                    Cancelar
                  </div>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="evidence_action" width="500">
        <v-card
          style="border-radius: 10px"
          :style="{
            background: $vuetify.theme.themes[theme].images,
          }"
        >
          <v-card-title class="text-h5 font-weight-bold">
            Evidencia
          </v-card-title>
          <v-card-text>
            <div class="text-subtitle-2 my-2">
              Información del registro de actividad
            </div>
            <v-select
              :items="evidences_types"
              item-value="id"
              item-text="type"
              v-model="evidence.evidence_type"
              filled
              dense
              hide-details
              label="Actividad evaluada"
              no-data-text="No hay evidencias"
            ></v-select>
            <v-text-field
              v-model="evidence.date"
              type="date"
              filled
              dense
              hide-details
              label="Fecha de evaluación"
              class="my-4"
            ></v-text-field>
            <v-expand-transition>
              <div v-show="error">
                <v-alert dense text type="error">
                  <div class="text-subtitle-2">{{ errorInfo }}</div>
                </v-alert>
              </div>
            </v-expand-transition>
          </v-card-text>
          <v-card-text
            :style="{
              background: $vuetify.theme.themes[theme].background,
            }"
          >
            <v-row align="center">
              <v-col cols="6">
                <v-btn
                  @click="saveEvidence()"
                  color="primary"
                  dark
                  elevation="0"
                  block
                >
                  <div class="text-subtitle-2" style="text-transform: none">
                    Guardar
                  </div>
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn
                  @click="evidence_action = false"
                  outlined
                  elevation="0"
                  block
                >
                  <div class="text-subtitle-2" style="text-transform: none">
                    Cancelar
                  </div>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="tester" width="80" persistent>
        <v-card style="border-radius: 10px" loading="true">
          <div class="text-center my-6">
            <v-progress-circular
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="downloading"
        full-screen
        persistent
        :no-click-animation="true"
      >
        <v-snackbar
          v-model="downloading"
          color="primary"
          dark
          border="left"
          :timeout="-1"
          bottom
          left
        >
          <v-list-item>
            <v-list-item-avatar>
              <v-icon>mdi-download-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <div class="text-subtitle-2">Descargando...</div>
              <div class="text-body-2">Tiempo máximo: 120 seg.</div>
            </v-list-item-content>
            <v-list-item-action>
              <v-progress-circular indeterminate></v-progress-circular>
            </v-list-item-action>
          </v-list-item>
        </v-snackbar>
      </v-dialog>
      <v-snackbar v-model="responseView" timeout="2000" color="info" top right>
        <div class="text-center text-subtitle-2">
          {{ responseMessage }}
        </div>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
// plugins
import functions from "../../../plugins/functions";
import auth from "../../../plugins/auth";
import settings from "../../../plugins/settings";
import periods from "../../../plugins/periods";
import lessons from "../../../plugins/lessons";
import curriculum from "../../../plugins/curriculum";
import students_active from "../../../plugins/students_active";
import partial from "../../../plugins/partial";
import assists from "../../../plugins/assists";
import assists_list from "../../../plugins/assists_list";
import evidences_types from "../../../plugins/evidences_types";
import evidences from "../../../plugins/evidences";
import evidences_list from "../../../plugins/evidences_list";
import reports from "../../../plugins/reports";

// resources
import endpoint from "../../../plugins/endpoint";

export default {
  name: "Lesson",

  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    numberOfPagesAs() {
      return Math.ceil(this.assists.length / this.itemsPerPageAs);
    },
    numberOfPagesEv() {
      return Math.ceil(this.evidences.length / this.itemsPerPageEv);
    },
  },

  created() {
    this.main();
  },

  data: () => ({
    period: "",
    period_model: {},

    lesson: {},
    subject: {},
    subject_icon: "Ninguna",

    students: [],
    partial: {},
    back_url: "",

    assists: [],
    assist: {},
    assist_action: false,

    assist_list: {},

    evidences: [],
    evidence: {},
    evidence_action: false,

    evidence_list: {},
    evidences_types: [],

    insert: true,

    custom: false,
    partials: [
      { id: 1, name: "Primer parcial" },
      { id: 2, name: "Segundo parcial" },
      { id: 3, name: "Tercer parcial" },
    ],

    // Reportes --->
    reports: [
      {
        id: 1,
        name: "Lista de asistencias",
        icon: "mdi-clipboard-list-outline",
      },
      {
        id: 2,
        name: "Lista de evidencias",
        icon: "mdi-chart-bar-stacked",
      },
      { id: 3, name: "Prueba escrita", icon: "mdi-chart-box-outline" },
      {
        id: 4,
        name: "Acta",
        icon: "mdi-file-table-box-outline",
      },
    ],
    downloading: false,

    compiler: false,
    loading: false,
    tester: false,

    responseView: false,
    responseMessage: "",

    error: false,
    errorInfo: "",

    itemsPerPageArrayAs: [3, 6, 9],
    pageAs: 1,
    itemsPerPageAs: 3,

    itemsPerPageArrayEv: [3, 6, 9],
    pageEv: 1,
    itemsPerPageEv: 3,
  }),

  methods: {
    // start chain
    async main() {
      if (!auth.getSession()) this.$router.push("/");
      else {
        this.loading = true;
        this.back_url = endpoint.BASE_PATH;

        await auth.permission().then((response) => {
          if (response.data.directive == "teacher") this.getLesson();
          else this.$router.push("/");
        });
      }
    },

    async getLesson() {
      await lessons.getOne(this.$route.params.id).then((response) => {
        this.lesson = response.data;
        if (response.data.status == "error") this.goBack();
        else this.directive();
      });
    },

    async directive() {
      await auth.identity().then((response) => {
        if (response.data.directive != this.lesson.teacher) this.goBack();
        else this.getSubject();
      });
    },

    async getSubject() {
      await curriculum.getOne(this.lesson.subject).then((response) => {
        this.subject = response.data;
        this.subject_icon = response.data.icon;

        this.getSettings();
      });
    },

    async getSettings() {
      await settings.getOne(1).then((response) => {
        this.period = response.data.period;

        this.getPeriods();
      });
    },

    async getPeriods() {
      await periods.getOne(this.period).then((response) => {
        this.period_model = response.data;

        this.getStudents();
      });
    },

    async getStudents() {
      this.loading = true;
      await students_active
        .getLesson(
          this.subject.id,
          this.lesson.semester,
          this.lesson.groups,
          this.period
        )
        .then((response) => {
          this.students = response.data;

          this.loading = false;
          this.compiler = true;

          this.getPartial();
        });
    },

    async getAssists() {
      this.loading = true;
      this.compiler = false;
      await assists
        .get(this.period, this.period_model.partial, this.lesson.id)
        .then((response) => {
          this.assists = response.data;

          this.loading = false;
          this.compiler = true;
        });
    },

    async getEvidences() {
      this.loading = true;
      this.compiler = false;
      await evidences
        .get(this.period, this.period_model.partial, this.lesson.id)
        .then((response) => {
          this.evidences = response.data;

          this.loading = false;
          this.compiler = true;
        });
    },

    // invoked
    getPartial() {
      this.testStudents();

      this.getAssists();
      this.getEvidences();
    },

    async openAssist() {
      this.assist = {};
      this.assist.date = functions.inputDate(new Date());

      this.assist_action = true;
    },

    async saveAssist() {
      this.loading = true;
      this.buildAssist();

      await assists.insert(this.assist).then((response) => {
        this.responseMessage = response.data.message;

        // partial kardex balance
        this.assist_action = false;
        this.insertClassList();
      });
    },

    goAssist(assist) {
      this.$router.push("/op/ls/" + this.lesson.id + "/as/" + assist);
    },

    async openEvidence() {
      await evidences_types.get().then((response) => {
        this.evidences_types = response.data;

        this.evidence = {};
        this.evidence.date = functions.inputDate(new Date());
        this.evidence_action = true;
      });
    },

    async saveEvidence() {
      if (
        this.evidence.evidence_type != null &&
        this.evidence.evidence_type != ""
      ) {
        this.loading = true;
        this.buildEvidence();

        await evidences.insert(this.evidence).then((response) => {
          this.responseMessage = response.data.message;

          this.evidence_action = false;
          this.insertEvidenceList();
        });
      } else this.showError("Seleccionar actividad a evaluar");
    },

    goEvidence(evidence) {
      this.$router.push("/op/ls/" + this.lesson.id + "/ev/" + evidence);
    },

    goRatings() {
      this.$router.push("/op/ls/" + this.lesson.id + "/rt");
    },

    goRisk() {
      this.$router.push("/op/ls/" + this.lesson.id + "/rk");
    },

    goBack() {
      this.$router.push("/op");
    },

    // cycled
    async testStudents() {
      this.tester = true;
      this.loading = true;
      this.compiler = false;

      this.buildPartial();

      Promise.all(
        this.students.map(async (std) => {
          await partial
            .getBy(
              this.period_model.partial,
              this.lesson.id,
              std.student,
              this.period
            )
            .then(async (response) => {
              if (response.data.status == "error") {
                this.partial.student = std.student;
                await partial.insert(this.partial).then((response) => {
                  this.responseMessage = response.data.message;
                  this.tester = false;
                });
              } else this.tester = false;
            });
        })
      ).then(() => {
        this.tester = false;
        this.loading = false;
        this.compiler = true;
      });
    },

    async insertClassList() {
      await assists
        .getByDate(
          this.period,
          this.period_model.partial,
          this.assist.date,
          this.lesson.id
        )
        .then((response) => {
          this.assist = response.data;
          this.buildAssistList();

          Promise.all(
            this.students.map(async (student) => {
              this.assist_list.student = student.student;

              await assists_list
                .insert(this.assist_list)
                .then(async (response) => {
                  console.log(response.data.message);

                  await assists_list
                    .getTotal(
                      this.lesson.id,
                      student.student,
                      this.period_model.partial,
                      this.period
                    )
                    .then(async (response) => {
                      var total = response.data.total;

                      var fouls =
                        parseFloat(this.assists.length + 1) - parseFloat(total);

                      this.partial = {};
                      this.partial.fouls = fouls;
                      await partial
                        .updateBy(
                          this.period_model.partial,
                          this.lesson.id,
                          student.student,
                          this.period,
                          this.partial
                        )
                        .then((response) => {
                          console.log(response.data.message);
                        });
                    });
                });
            })
          ).then(() => {
            this.goAssist(this.assist.id);
          });
        });
    },

    async insertEvidenceList() {
      await evidences
        .getBy(
          this.period,
          this.period_model.partial,
          this.evidence.date,
          this.lesson.id,
          this.evidence.evidence_type
        )
        .then((response) => {
          this.evidence = response.data;
          this.buildEvidenceList();

          Promise.all(
            this.students.map(async (std) => {
              this.evidence_list.student = std.student;

              await evidences_list
                .insert(this.evidence_list)
                .then((response) => {
                  console.log(response.data.message);
                });
            })
          ).then(() => {
            this.goEvidence(this.evidence.id);
          });
        });
    },

    // --- reports --->
    // exports
    async reincoDownload(type, name) {
      this.downloading = true;
      var fileDownload = require("js-file-download");
      await reports
        .getReinco(
          type,
          this.lesson.subject,
          this.lesson.id,
          this.lesson.semester,
          this.lesson.groups,
          this.lesson.period,
          this.period_model.partial
        )
        .then((response) => {
          fileDownload(
            response.data,
            this.lesson.semester +
              "° " +
              this.lesson.groups +
              " - " +
              name +
              " de " +
              this.period_model.partial +
              "° parcial " +
              " (" +
              this.period +
              ").pdf"
          );
          this.downloading = false;
        });
    },
    // --- end reports --->

    // support / embedded
    buildPartial() {
      this.partial.partial = this.period_model.partial;
      this.partial.lesson = this.lesson.id;
      this.partial.subject = this.subject.id;
      this.partial.fouls = 0;
      this.partial.evidences = "0";
      this.partial.evidences_value = "0";
      this.partial.exam = "0";
      this.partial.exam_value = "0";
      this.partial.rating_number = "0";
      this.partial.rating_letter = "CERO";
      this.partial.period = this.period;
    },
    buildAssist() {
      this.assist.period = this.period;
      this.assist.partial = this.period_model.partial;
      this.assist.lesson = this.lesson.id;
    },
    buildAssistList() {
      this.assist_list.assist = this.assist.id;
      this.assist_list.lesson = this.lesson.id;
      this.assist_list.value = true;
      this.assist_list.partial = this.assist.partial;
      this.assist_list.period = this.assist.period;
      this.assist_list.date = this.assist.date;
    },
    restartAssist() {
      this.getPartial();
      this.loading = false;
      this.responseView = true;
    },
    buildEvidence() {
      this.evidence.period = this.period;
      this.evidence.partial = this.period_model.partial;
      this.evidence.lesson = this.lesson.id;
    },
    buildEvidenceList() {
      this.evidence_list.evidence = this.evidence.id;
      this.evidence_list.lesson = this.evidence.lesson;
      this.evidence_list.date = this.evidence.date;
      this.evidence_list.rating = "";
      this.evidence_list.comment = "";
      this.evidence_list.partial = this.evidence.partial;
      this.evidence_list.period = this.evidence.period;
      this.evidence_list.evidence_type = this.evidence.evidence_type;
    },
    showError(message) {
      this.errorInfo = message;
      this.error = true;
      setTimeout(() => (this.error = false), 3000);
    },
    nextPageAs() {
      if (this.pageAs + 1 <= this.numberOfPagesAs) this.pageAs += 1;
    },
    formerPageAs() {
      if (this.pageAs - 1 >= 1) this.pageAs -= 1;
    },
    updateItemsPerPageAs(number) {
      this.itemsPerPageAs = number;
    },
    nextPageEv() {
      if (this.pageEv + 1 <= this.numberOfPagesEv) this.pageEv += 1;
    },
    formerPageEv() {
      if (this.pageEv - 1 >= 1) this.pageEv -= 1;
    },
    updateItemsPerPageEv(number) {
      this.itemsPerPageEv = number;
    },
  },
};
</script>