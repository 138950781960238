<template>
  <div class="contain">
    <v-progress-linear fixed :active="loading" :indeterminate="true"></v-progress-linear>
    <v-container v-show="compiler">
      <v-row>
        <v-col cols="12">
          <a @click="goBack()">
            <div class="text-subtitle-2">
              <v-icon class="mr-2" color="primary">mdi-chevron-left</v-icon>
              Administración
            </div>
          </a>
        </v-col>
        <v-fade-transition>
          <v-col cols="12" lg="4" xl="3" v-show="compiler">
            <v-card style="border-radius: 10px" :style="{
              background: $vuetify.theme.themes[theme].images,
            }">
              <v-card-text>
                <v-row align="center">
                  <v-col cols="12" md="3" lg="12">
                    <div class="text-center">
                      <div class="mt-5 d-md-none d-lg-flex"></div>
                      <v-avatar size="120">
                        <v-img lazy-src="../../../assets/images/default.png"
                          src="../../../assets/images/reports.png"></v-img>
                      </v-avatar>
                    </div>
                  </v-col>
                  <v-col cols="12" md="6" lg="12">
                    <div class="text-center">
                      <div class="text-h5 text--primary font-weight-bold">
                        Datos
                      </div>
                      <div class="text-subtitle-2">
                        Reportes, gráficos y bases de datos
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" md="3" lg="12">
                    <div class="text-center">
                      <v-btn color="primary" outlined elevation="0" disabled>
                        <div class="text-subtitle-2" style="text-transform: none">
                          Próximamente
                        </div>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-fade-transition>
        <v-slide-y-reverse-transition>
          <v-col cols="12" lg="8" xl="9" v-show="compiler">
            <v-card style="border-radius: 10px" :style="{
              background: $vuetify.theme.themes[theme].images,
            }" class="mb-6">
              <v-list-item>
                <v-list-item-title class="text-subtitle-2">
                  Reporte para SICOBA
                </v-list-item-title>
              </v-list-item>
              <v-divider></v-divider>
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="12" md="4">
                    <v-select v-model="period" v-on:input="getGroups()" :items="periods" item-value="name"
                      item-text="name" label="Seleccionar periodo" outlined hide-details></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-select v-model="semester" v-on:input="getGroups()" :items="semesters" item-value="id"
                      item-text="name" label="Seleccionar semestre" outlined hide-details></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="2">
                    <v-select v-model="group_id" v-on:input="getSICOBA()" :items="groups" item-value="groups"
                      item-text="groups" label="Grupo" outlined hide-details no-data-text="No hay grupos"></v-select>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-slide-x-transition>
                <v-list-item v-if="sicoba" @click="sicoba_st = !sicoba_st" link>
                  <v-list-item-avatar>
                    <v-icon>mdi-account-details-outline</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-title class="text-subtitle-2">
                    Lista de SICOBA (por estudiantes)
                  </v-list-item-title>
                  <v-list-item-action>
                    <v-icon>
                      {{ sicoba_st ? "mdi-chevron-up" : "mdi-chevron-down" }}
                    </v-icon>
                  </v-list-item-action>
                </v-list-item>
              </v-slide-x-transition>

              <v-expand-transition>
                <div class="sicoba" v-if="sicoba_st">
                  <v-divider></v-divider>

                  <v-card-text>
                    <v-list-item v-for="item in sicobaListSt" :key="item.id" link>
                      <v-list-item-title class="text-caption">
                        <span class="text--secondary">
                          <v-icon left small>mdi-account-outline</v-icon>
                          {{ item.paternal_surname }}
                          {{ item.maternal_surname }} {{ item.names }}
                        </span>
                        <span class="text--primary font-weight-medium">
                          <v-icon left small class="ml-4">
                            mdi-bookmark-outline
                          </v-icon>
                          ({{ item.name }})
                        </span>
                        <span class="font-weight-bold" :class="item.average >= 6 ? 'primary--text' : 'error--text'
                          ">
                          <v-icon left small class="ml-4">
                            mdi-star-outline
                          </v-icon>
                          Prom: {{ item.average }}
                        </span>
                        <span class="font-weight-bold" :class="item.number >= 6 ? 'success--text' : 'error--text'
                          ">
                          (Fin: {{ item.number }})
                        </span>
                      </v-list-item-title>
                      <v-list-item-action>
                        <div class="text-center text-subtitle-2 primary--text">
                          {{ item.app_order }}
                        </div>
                      </v-list-item-action>
                    </v-list-item>
                  </v-card-text>
                </div>
              </v-expand-transition>

              <v-slide-x-transition>
                <v-list-item v-if="sicoba" @click="sicoba_sb = !sicoba_sb" link>
                  <v-list-item-avatar>
                    <v-icon>mdi-bookmark-box-outline</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-title class="text-subtitle-2">
                    Lista de SICOBA (por materias)
                  </v-list-item-title>
                  <v-list-item-action>
                    <v-icon>
                      {{ sicoba_sb ? "mdi-chevron-up" : "mdi-chevron-down" }}
                    </v-icon>
                  </v-list-item-action>
                </v-list-item>
              </v-slide-x-transition>

              <v-expand-transition>
                <div class="sicoba" v-if="sicoba_sb">
                  <v-divider></v-divider>

                  <v-card-text>
                    <v-list-item v-for="item in sicobaListSb" :key="item.id" link>
                      <v-list-item-title class="text-caption">
                        <span class="text--secondary">
                          <v-icon left small>mdi-account-outline</v-icon>
                          {{ item.paternal_surname }}
                          {{ item.maternal_surname }} {{ item.names }}
                        </span>
                        <span class="text--primary font-weight-medium">
                          <v-icon left small class="ml-4">
                            mdi-bookmark-outline
                          </v-icon>
                          ({{ item.name }})
                        </span>
                        <span class="font-weight-bold" :class="item.average >= 6 ? 'primary--text' : 'error--text'
                          ">
                          <v-icon left small class="ml-4">
                            mdi-star-outline
                          </v-icon>
                          Prom: {{ item.average }}
                        </span>
                        <span class="font-weight-bold" :class="item.number >= 6 ? 'success--text' : 'error--text'
                          ">
                          (Fin: {{ item.number }})
                        </span>
                      </v-list-item-title>
                      <v-list-item-action>
                        <div class="text-center text-subtitle-2 primary--text">
                          {{ item.app_order }}
                        </div>
                      </v-list-item-action>
                    </v-list-item>
                  </v-card-text>
                </div>
              </v-expand-transition>
            </v-card>

            <v-card style="border-radius: 10px" :style="{
              background: $vuetify.theme.themes[theme].images,
            }" class="mb-6">
              <v-list-item>
                <v-list-item-title class="text-subtitle-2">
                  Comprobante de inscripción
                </v-list-item-title>
              </v-list-item>
              <v-divider></v-divider>
              <v-card-text>
                <div class="text-subtitle-2">Conceptos de cobro</div>
                <v-row class="my-2">
                  <v-col cols="12" md="7" lg="8">
                    <v-select v-model="receiptSemester" :items="[
                      { id: 1, name: 'Primer semestre' },
                      { id: 2, name: 'Segundo semestre' },
                      { id: 3, name: 'Tercer semestre' },
                      { id: 4, name: 'Cuarto semestre' },
                      { id: 5, name: 'Quinto semestre' },
                      { id: 6, name: 'Sexto semestre' }
                    ]" item-value="id" item-text="name" hide-details label="Semestre" outlined></v-select>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-list-item link>
                <v-list-item-avatar>
                  <v-icon>mdi-receipt-text-check-outline</v-icon>
                </v-list-item-avatar>
                <v-list-item-title>
                  <div class="text-subtitle-2 text-truncate">
                    <span class="primary--text">
                      $650.00
                    </span>
                    (Cuotas de recuperación para personas retrasaditas mentales como el contador que gay es la neta)
                  </div>
                  <div class="text-caption">
                    CATEGORÍA
                  </div>
                </v-list-item-title>
                <v-list-item-action>
                  <v-icon color="primary">mdi-circle-edit-outline</v-icon>
                </v-list-item-action>
              </v-list-item>
              <v-divider class="mt-8"></v-divider>

              <v-list-item @click="categories = !categories" link nav>
                <v-list-item-title class="text-subtitle-2 text-truncate">
                  Categorías
                </v-list-item-title>
                <v-list-item-action>
                  <v-icon>{{ categories ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                </v-list-item-action>
              </v-list-item>
              <v-expand-transition>
                <div class="categories" v-if="categories">
                  <v-card-text>
                    <v-text-field v-model="category.name" filled dense hide-details label="Nombre de la categoría"
                      append-icon="mdi-plus" @click:append="saveCat()" class="mb-4"></v-text-field>
                  </v-card-text>
                </div>
              </v-expand-transition>
            </v-card>
          </v-col>
        </v-slide-y-reverse-transition>
      </v-row>
      <v-snackbar v-model="responseView" timeout="2000" color="info" top right>
        <div class="text-center text-subtitle-2">
          {{ responseMessage }}
        </div>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
// plugins
import auth from "../../../plugins/auth";
import settings from "../../../plugins/settings";
import periods from "../../../plugins/periods";
import groups from "../../../plugins/groups";
import lessons from "../../../plugins/lessons";
import final from "../../../plugins/final";

// resources
import endpoint from "../../../plugins/endpoint";

export default {
  name: "Reports",

  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    numberOfPages() {
      return Math.ceil(this.evidences_types.length / this.itemsPerPage);
    },
  },

  created() {
    this.main();
  },

  data: () => ({
    period: "",
    period_model: {},

    back_url: "",

    semester: 0,
    group_id: 0,
    lessons: [],

    semesters: [
      { id: 1, name: "Primer semestre" },
      { id: 2, name: "Segundo semestre" },
      { id: 3, name: "Tercer semestre" },
      { id: 4, name: "Cuarto semestre" },
      { id: 5, name: "Quinto semestre" },
      { id: 6, name: "Sexto semestre" },
    ],
    groups: [],
    partials: [
      { id: 1, name: "Primer parcial" },
      { id: 2, name: "Segundo parcial" },
      { id: 3, name: "Tercer parcial" },
    ],
    periods: [
      { name: "2021-2022" },
      { name: "2022-2022" },
      { name: "2022-2023" },
      { name: "2023-2023" },
      { name: "2023-2024" },
      { name: "2024-2024" },
      { name: "2024-2025" },
      { name: "2025-2025" },
    ],

    sicoba: false,
    sicoba_st: false,
    sicobaListSt: [],
    sicoba_sb: false,
    sicobaListSb: [],

    lesson: 0,
    lesson_data: {},
    lessons: [],

    loading: false,
    compiler: false,

    responseView: false,
    responseMessage: "",

    error: false,
    errorInfo: "",

    itemsPerPageArray: [3, 6, 9],
    page: 1,
    itemsPerPage: 3,

    categories: false,
    cateogriesList: [],
    category: {},
  }),

  methods: {
    // start chain
    async main() {
      if (!auth.getSession()) this.$router.push("/");
      else {
        this.loading = true;
        this.back_url = endpoint.BASE_PATH;

        await auth.permission().then((response) => {
          if (
            response.data.directive == "admin" ||
            response.data.directive == "supervisor"
          )
            this.getSettings();
          else this.$router.push("/");
        });
      }
    },

    async getSettings() {
      await settings.getOne(1).then((response) => {
        this.period = response.data.period;

        this.getPeriod();
      });
    },

    async getPeriod() {
      await periods.getOne(this.period).then((response) => {
        this.period_model = response.data;

        this.loading = false;
        this.compiler = true;
      });
    },

    // Invoked
    async getGroups() {
      this.loading = true;
      this.group_id = "";
      this.lessons = [];

      this.sicoba = false;
      this.sicoba_st = false;
      this.sicobaListSt = [];
      this.sicoba_sb = false;
      this.sicobaListSb = [];
      await groups.get(this.semester, this.period).then((response) => {
        this.groups = response.data;
        this.stats = false;
        this.loading = false;
      });
    },

    async getSICOBA() {
      this.loading = true;
      await final
        .getSICOBA(this.semester, this.group_id, this.period, 1)
        .then(async (response) => {
          this.sicobaListSt = response.data;
          console.log(this.sicobaListSt);

          await final
            .getSICOBA(this.semester, this.group_id, this.period, 2)
            .then((response) => {
              this.sicobaListSb = response.data;
              console.log(this.sicobaListSb);

              this.sicoba = true;
              this.loading = false;
            });
        });
    },

    async getLessons() {
      this.loading = true;
      await lessons
        .listByGroup(this.semester, this.group_id, this.period)
        .then((response) => {
          this.lessons = response.data;
          this.loading = false;
          if (this.group_id != "") this.stats = true;
          else this.stats = false;
        });
    },

    goBack() {
      this.$router.push("/ad");
    },

    // support
    showError(message) {
      this.errorInfo = message;
      this.error = true;
      setTimeout(() => (this.error = false), 3000);
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },
  },
};
</script>